/**
 * @Author: Firmiana
 * @Date: 2021-08-01 16:43:58
 * @Desc: h5页面路由统一配置
 */

import loadable from '../loadable'

export interface routeT {
  path: string
  // component: React.ReactNode
  component?: React.ComponentType // 页面组件
  title?: string
  redirect?: string

  // 拥有这里面的任意一个权限即可访问该路由，这个为空，或者无，表示这个路由无需权限即可访问。
  // 这里面具体填什么值，要看这个页面需要调哪些接口，那些接口分别需要什么权限（问后端），把这些需要的权限全部填进来
  permissions?: Set<string>

  isMenu?: boolean // 是否出现在菜单中
  noNeedLayout?: boolean // 该页面是否需要左侧菜单栏和顶部信息栏
  menuIcon?: string | React.ComponentType // 菜单icon。如果是字符串，要先到 @view/layoutPage/LayoutPageMainMenu.MenuIconMap 中，加上你需要的icon
  // 更多子路由
  // ps. 如果有`/a/b/c`和`/a/b/c/d`这两种路由同时存在，后者在数组中要放在前者的前面，否则 react-router-dom 会认为`/a/b/c`就已经匹配url了
  routes?: routeT[]
}
export type routesT = routeT[]

export const h5RoutesList: routesT = [
  {
    path: '/home',
    component: loadable(() => import('@view/homePage/HomePage')),
    title: '首页',
  },
  {
    path: '/home-search',
    component: loadable(() => import('@view/homeSearch/HomeSearch')),
    title: '搜索',
  },
  {
    path: '/home-second',
    component: loadable(() => import('@view/homeSecond/HomeSecond')),
    title: '二手房列表',
  },
  {
    path: '/second-detail',
    component: loadable(() => import('@view/homeSecond/HomeSecondDetail')),
    title: '二手房详情',
  },
  {
    path: '/home-new',
    component: loadable(() => import('@view/homeNew/HomeNew')),
    title: '新房列表',
  },
  {
    path: '/new-detail',
    component: loadable(() => import('@view/homeNew/HomeNewDetail')),
    title: '新房详情',
  },
  {
    path: '/new-model',
    component: loadable(() => import('@src/view/homeNew/HomeNewModel')),
    title: '新房户型列表',
  },
  {
    path: '/detail_info',
    component: loadable(() => import('@view/homeNew/HomeDetailInfo')),
    title: '新房详情',
  },
  {
    path: '/home-rent',
    component: loadable(() => import('@view/homeRent/HomeRent')),
    title: '租房列表',
  },
  {
    path: '/rent-detail',
    component: loadable(() => import('@view/homeRent/HomeRentDetail')),
    title: '租房详情',
  },
  {
    path: '/broker',
    component: loadable(() => import('@view/broker/BrokerPage')),
    title: '经纪人列表',
  },
  {
    path: '/broker-detail',
    component: loadable(() => import('@view/broker/BrokerDetail')),
    title: '经纪人详情',
  },

  {
    path: '/encyclopedias',
    component: loadable(() =>
      import('@view/houseEncyclopedias/HouseEncyclopedias')
    ),
    title: '房产百科',
  },
  {
    path: '/encyclopedias-detail',
    component: loadable(() =>
      import('@view/houseEncyclopedias/HouseEncyclopediasDetail')
    ),
    title: '房产百科',
  },
  {
    path: '/house-evaluate',
    component: loadable(() => import('@view/houseEvaluate/HouseEvaluate')),
    title: '房源点评',
  },
  // 未开发路由
  {
    path: '/community',
    component: loadable(() => import('@view/community/Community')),
    title: '小区列表',
  },
  {
    path: '/community-detail',
    component: loadable(() => import('@view/community/CommunityDetail')),
    title: '小区详情',
  },
  {
    path: '/community-more-house',
    component: loadable(() => import('@view/community/HouseList')),
    title: '小区更多房源',
  },
   {
    path: '/activity-999',
    component: loadable(() => import('@view/activity/Activity-999')),
    title: '999买房服务',
  }
]
