/**
 * @format
 * @Author: Firmiana
 * @Date: 2020-04-23 16:44:22
 * @Desc: 系统相关
 */

import * as types from '@store/mutation-types'

// eslint-disable-next-line no-unused-vars
import {objString, objSF} from '@customTypes/index'

interface effectParamsT {
  params: objSF
  conf: objString
}

export interface SysStateT {
  token: string
  menu: any[]
  router: any[]
  tabs: any
  permissions: Set<string>
  companyInfo?: {
    name?: string
    contactAddress?: string
    customerServiceTel?: string
    employeeNum?: number
    expireTime?: string
    icon?: string
    maxEmployeeNum?: number
    sid?: string
    signTime?: string
    state?: number
    superviseTel?: string
  }
  userInfo?: {
    userName?: string
    role?: string | number
    avatar?: string
    cTime?: string
    cardImg?: string
    client?: number
    companyId?: string
    createWay?: number
    dFlag?: number
    id?: number
    industry?: string
    loginType?: string
    mTime?: string
    maxEmployeeNum?: number
    mobile?: string
    remark?: string
    sex?: number
    state?: number
    superiorId?: number
    uid?: string
    wechatNumber?: string
    wechatQrcode?: string
  }
  financeInfo?: {
    surpluses?: number
    totalFee?: number
  }
}

const state: SysStateT = {
  token: '',
  menu: [],
  userInfo: {},
  companyInfo: {},
  financeInfo: {},
  router: [],
  tabs: {},
  permissions: new Set<string>()
}

export default {
  state: state,
  mutations: {
    [types.SYS_TOKEN_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_USERINFO_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_COMPANYINFO_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_FINANCEINFO_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_PERMISSIONS_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_MENU_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_ROUTER_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    },
    [types.SYS_TABS_UPDATE]: (state: object, info: object) => {
      return {
        ...state,
        ...info
      }
    }
  },
  effects: {
    [types.SYS_TOKEN_GET]: async (
      // eslint-disable-next-line no-unused-vars
      {params, conf = {}}: effectParamsT,
      {dispatch}: {dispatch: Function}
    ) => {
      // console.log(params, conf)
      dispatch({
        type: types.SYS_TOKEN_UPDATE,
        payload: {
          token: params.token
        }
      })
    }
  }
}
