/**
 * @format
 * @Author: huweijian
 * @Date: 2019-11-07 11:58:23
 * @Desc: reducer type 集合
 */

// -------->test专属type开始
export const TEST_DETAIL_GET = 'TEST_DETAIL_GET'
export const TEST_DETAIL_UPDATE = 'TEST_DETAIL_UPDATE'
// <---------test专属type结束

// -------->sys相关开始
export const SYS_TOKEN_GET = 'SYS_TOKEN_GET'

export const SYS_TOKEN_UPDATE = 'SYS_TOKEN_UPDATE'
export const SYS_USERINFO_UPDATE = 'SYS_USERINFO_UPDATE' // 更新用户信息
export const SYS_COMPANYINFO_UPDATE = 'SYS_COMPANYINFO_UPDATE' // 更新企业信息
export const SYS_FINANCEINFO_UPDATE = 'SYS_FINANCEINFO_UPDATE' // 更新企业财务信息
export const SYS_MENU_UPDATE = 'SYS_MENU_UPDATE' // 更新菜单
export const SYS_PERMISSIONS_UPDATE = 'SYS_PERMISSIONS_UPDATE' // 更新登录用户拥有的权限
export const SYS_ROUTER_UPDATE = 'SYS_ROUTER_UPDATE' // 更新动态匹配的权限路由
export const SYS_TABS_UPDATE = 'SYS_TABS_UPDATE' // 更新动态匹配的权限路由
// <---------sys相关结束
